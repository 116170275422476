.update-tenant-basics-modal {
  --modal-height: 90vh;
  --modal-header-height: 110px;
  --modal-bottom-buttons-height: 110px;

  --modal-content-height: calc(var(--modal-height) - var(--modal-header-height) - var(--modal-bottom-buttons-height));

  height: var(--modal-height);
  width: 90vw;
  min-width: 500px;
  background-color: #fff;

  .modal-contents {
    flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .headline {
      height: var(--modal-header-height);
      width: 100%;
      padding: 40px 50px 30px;
      color: var(--Gray900);
      font-size: 32px;
      font-weight: 700;
      letter-spacing: 0.75px;
      border-bottom: 1px solid #eaeaea;
    }

    .content {
      height: var(--modal-content-height);
      padding: 30px 50px 0px;
      display: flex;

      .info-col,
      .address-col {
        flex: 1;
        height: var(----details-container-height);
        padding-right: 6px; // to accomodate box shadows on inputs
        overflow-y: scroll;
      }

      .address-col {
        margin-left: 48px;
      }

      .info-container {
        display: flex;

        .info-labels,
        .info-values {
          margin-left: 12px;
          display: flex;
          flex-direction: column;

          .info-label,
          .info-value {
            &:not(:last-child) {
              margin-bottom: 12px;
            }

            &.editable {
              display: flex;
              align-items: center;
              height: 60px;
            }
          }

          .info-label {
            color: var(--secondary-color);
          }
          .info-value {
            width: 100%;
            max-width: 350px;

            &.long {
              max-width: 100%;
            }
          }
        }

        .info-labels {
          align-items: flex-end;
        }

        .info-values {
          flex: 1;
          margin-left: 24px;
          align-items: flex-start;
        }
      }
    }
  }

  .bottom-buttons {
    height: var(--modal-bottom-buttons-height);
    margin: 0px 40px;
    padding: 30px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #eaeaea;

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--NickelPrimary);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--Gray700);
        border: 2px solid var(--Gray700);
      }
    }
  }
}
