html {
  --header-height: 88px;
  --sidebar-width: 244px;

  --content-height: calc(100vh - var(--header-height));
  --content-width: calc(100vw - var(--sidebar-width));
}

* {
  box-sizing: border-box;
}
