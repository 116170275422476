.character-counter {
  width: 100%;
  height: 14px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: var(--Gray500);
}
