.fullscreen-modal {
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(2px);
  opacity: 0;
  transition: opacity 200ms ease-in;
  pointer-events: none;
  font-family: Muli, sans-serif;

  &.open {
    opacity: 1;
    pointer-events: inherit;

    .loading-overlay {
      z-index: 1001;
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;

    &:active {
      opacity: 0.7;
    }
  }
}
