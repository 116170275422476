table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  border-collapse: collapse;
  font-variant-numeric: tabular-nums;

  th {
    padding: 10px 0 10px 16px;
    color: var(--Gray600);
    background-color: #f7f6f6;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
    vertical-align: bottom;
    border-bottom: 1px solid #eaeaea;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }
  }

  td {
    border-bottom: 1px solid #eaeaea;
    padding: 30px 0 30px 16px;
    font-weight: 500;
    vertical-align: top;

    &:last-child {
      padding-right: 16px;
      text-align: right;
    }

    .action {
      color: var(--NickelPrimary);
      font-weight: 700;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}
