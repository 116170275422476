.analytics {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 60px;
  background-color: #fafafa;
  overflow-y: scroll;

  &.analytics-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .analytics-header {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #eaeaea;

    .analytics-header-action {
      display: flex;
      align-items: center;
      color: var(--NickelPrimary);
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }

      .action-label-icon {
        height: 21px;
        width: 21px;
        margin-right: 4px;
        margin-bottom: -1px;
        stroke: var(--NickelPrimary);
        stroke-width: 3;
      }
    }
  }

  .analytics-body {
    display: flex;
  }
}
