html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

* {
  box-sizing: border-box;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}

a {
  text-decoration: none;
  color: initial;
}
