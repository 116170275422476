.label {
  min-width: 130px;
  margin-bottom: 8px;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--Gray900);
}
