.tenant-details {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 60px;
  background-color: #fafafa;
  overflow-y: scroll;

  .tenant-header {
    display: flex;
    align-items: center;

    .tenant-picture {
      position: relative;
      height: 160px;
      width: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      cursor: pointer;

      &:hover {
        &:after {
          content: "EDIT";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.75px;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      &:active {
        opacity: 0.8;
      }

      .tenant-image {
        height: 100%;
        width: 100%;
        object-fit: contain;
        object-position: center;
      }

      .tenant-picture-placeholder {
        height: 80px;
        width: 80px;
        stroke: var(--Gray600);
        opacity: 0.2;
      }
    }

    .tenant-name {
      margin-left: 32px;
      font-size: 40px;
      font-weight: 500;
      letter-spacing: 0.75px;
    }
  }

  .tenant-section {
    position: relative;
    width: 70%;
    max-width: 700px;
    margin-top: 60px;
    margin-left: 12px;

    .tenant-section-title {
      margin-bottom: 12px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.75px;
    }

    .section-item {
      display: flex;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      &.collapsed {
        margin-bottom: 4px;

        &.skills {
          margin-bottom: 8px;
        }
      }

      &.skills:not(.collapsed) + .collapsed {
        margin-top: 20px; // this will collapse into the 12px bottom margin of the other
      }

      .section-item-label {
        flex-shrink: 0;
        width: 180px;
        color: var(--Gray600);
        font-weight: 400;
        text-align: right;
      }

      .section-item-value {
        margin-left: 20px;
        font-weight: 400;

        .link {
          color: var(--NickelPrimary);
        }
      }
    }

    .edit-button {
      position: absolute;
      top: 38px;
      right: -80px;
      display: flex;
      color: var(--Gray600);
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }

      .edit-button-icon {
        height: 17px;
        width: 17px;
        margin-top: 2px;
        margin-right: 12px;
        stroke: var(--Gray600);
      }
    }
  }
}

.button {
  height: 44px;
  margin-left: 20px;
  padding: 6px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: var(--NickelPrimary);
  font-weight: 900;
  letter-spacing: 1px;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.7;
  }
}

.tenant-picture-upload-input {
  display: none;
}
