.add-tenant {
  height: var(--content-height);
  width: 100%;
  padding: 48px 120px 20px;
  overflow-y: scroll;

  .page-header {
    margin-bottom: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .page-title {
      display: flex;
      align-items: center;
      color: var(--NickelPrimary-dark-1);
      font-size: 24px;
      font-weight: 400;

      .title-input-label {
        margin-right: 20px;
      }

      input {
        min-width: 300px;
        height: 44px;
        padding: 6px 12px;
        font-size: 18px;
      }
    }

    .action-buttons {
      display: flex;
    }
  }

  .page-content {
    flex: 1;
    margin-bottom: 48px;
    display: flex;
    justify-content: flex-start;

    .content-column {
      width: 500px;
      margin-right: 80px;

      .content-section {
        min-height: 48px;
        width: 500px;
        margin-top: 36px;
        margin-bottom: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.logo {
          margin-top: 40px;
        }

        &.top-aligned {
          align-items: flex-start;

          .logo + .button {
            align-self: flex-end;
          }
        }

        .content-section-title {
          padding-right: 20px;
          color: var(--NickelPrimary-dark-1);
          font-size: 20px;
          font-weight: 400;
        }

        .logo {
          height: 200px;
          width: 200px;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }

  .button {
    height: 44px;
    margin-left: 20px;
    padding: 6px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: var(--NickelPrimary);
    font-weight: 900;
    letter-spacing: 1px;
    border-radius: 6px;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 0.7;
    }
  }

  input {
    height: 36px;
    min-width: 240px;
    padding: 4px 8px;
    border-radius: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    outline: none;

    &:last-child {
      margin-bottom: 0;
    }

    &::placeholder {
      color: #ccc;
    }
  }
}

.logo-upload-input {
  display: none;
}
