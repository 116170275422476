.dashboard {
  position: relative;
  height: var(--content-height);
  width: var(--content-width);
  padding: 60px;
  background-color: #fafafa;
  overflow-y: scroll;

  &.dashboard-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .dashboard-header {
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-bottom: 1px solid #eaeaea;

    .dashboard-header-action {
      display: flex;
      align-items: center;
      color: var(--NickelPrimary);
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }

      .action-label-icon {
        height: 21px;
        width: 21px;
        margin-right: 4px;
        margin-bottom: -1px;
        stroke: var(--NickelPrimary);
        stroke-width: 3;
      }
    }
  }

  .dashboard-body {
    display: flex;

    .main-col {
      flex: 1;

      .dashboard-subtitle {
        margin-bottom: 40px;
        color: var(--Gray600);
        font-weight: 500;
      }

      .dashboard-table {
        width: 100%;
        // styled in theme.scss
      }
    }

    .stats-col {
      width: 320px;
      margin-left: 48px;

      .group-by {
        margin-bottom: 60px;

        .group-by-title {
          margin-bottom: 12px;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 0.75px;
        }

        .group-by-options {
          margin-left: 12px;
        }
      }

      .stats-card {
        position: relative;
        width: 100%;
        margin-bottom: 20px;
        padding: 20px;
        color: var(--Gray600);
        background-color: #f7f6f6;
        text-align: left;
        border: 1px solid #dcdcdc;

        .stats-card-title {
          color: initial;
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 500;
        }

        .stat-item {
          &:not(:last-child) {
            margin-bottom: 20px;
          }
          .stat-item-label {
            margin-bottom: 4px;
            font-size: 14px;
          }

          .stat-item-value {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}
