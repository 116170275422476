.sidebar {
  position: relative;
  z-index: 999;
  height: var(--content-height);
  width: var(--sidebar-width);
  padding: 40px 30px;
  background-color: #f7f6f6;
  border-right: 1px solid #eaeaea;

  &.detail {
    // background-color: #fafafa;
    // border-right: none;
  }

  .links-section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }

    .sidebar-link-wrapper {
      display: block;

      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .sidebar-link {
        display: flex;
        align-items: center;
        color: var(--Gray600);
        font-weight: 700;
        letter-spacing: 0.75px;

        &.active {
          color: inherit;
        }

        &.back {
          color: var(--NickelPrimary);
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }

          &:active {
            opacity: 0.7;
          }
        }

        .link-icon {
          height: 20px;
          width: 20px;
          margin-right: 12px;
          stroke: var(--Gray600);
        }
        &.active .link-icon {
          stroke: var(--NickelPrimary);
        }

        &.back .link-icon {
          stroke: var(--NickelPrimary);
        }
      }
    }
  }
}
