.setup-password {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--White);

  .message {
    max-width: 70vw;
    margin-bottom: 56px;
    color: var(--NickelPrimaryDark);
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .inputs-container {
    height: 325px;
    width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);

    .auth-input {
      height: 44px;
      width: 70%;
      margin-bottom: 20px;
      padding: 6px 12px;
      border-radius: 6px;
      font-size: 18px;
      // border: 1px solid #aaa;
      border: none;
      outline: none;
      box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

      &:last-child {
        margin-bottom: 0;
      }

      &::placeholder {
        color: #ccc;
      }
    }

    .login-button {
      height: 44px;
      width: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--NickelPrimary);
      color: #fff;
      font-weight: 900;
      letter-spacing: 0.5px;
      border-radius: 6px;
      cursor: pointer;
      user-select: none;

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}
