.currency-input-component-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  .currency-input-container {
    position: relative;
    display: flex;
    justify-content: center;
    // z-index: 1;

    input {
      height: 40px;
      width: 100%;
      padding: 0px 8px;
      box-sizing: border-box;
      font-family: Muli, sans-serif;
      font-size: 16px;
      line-height: 34px;
      border-radius: 2px;
      outline: none;
      border: none;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

      &::placeholder {
        color: #222;
        opacity: 0.5;
        font-family: Montserrat;
      }
      &:focus {
        outline: none;
      }
    }

    .icon-container {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      background-color: var(--Gray200);
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: solid 1px var(--Gray400);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      // z-index: 1;

      > * {
        opacity: 0.3;
        color: var(--Black);
      }
    }

    .clearable-container {
      width: 40px;
      height: 40px;
      position: absolute;
      right: 0;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      // z-index: 1;

      > * {
        opacity: 0.3;
        color: var(--Gray700);
      }
    }
  }

  .character-counter-container {
    position: absolute;
    bottom: -16px;
    right: 16px;
    padding-top: 2px;
  }
}
