.putty-input {
  position: relative;
  width: 100%;
  height: 40px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  + .putty-input {
    margin-top: 16px;
  }

  input {
    height: 100%;
    width: 100%;
    padding: 0px 8px;
    box-sizing: border-box;
    font-family: Muli, sans-serif;
    font-size: 16px;
    line-height: 34px;
    border-radius: 2px;
    outline: none;
    border: none;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

    &::placeholder {
      color: #222;
      opacity: 0.5;
      font-family: Muli, sans-serif;
    }
  }

  .putty-input-error {
    width: 100%;
    margin: 0px;
    margin-top: 2px;
    margin-left: 8px;
    font-size: 10px;
    line-height: 12px;
    color: #e0614a;
  }

  .putty-input-error-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0, -50%);
    height: 16px;
    width: 16px;
  }
}
