.header {
  position: relative;
  z-index: 1000;
  height: var(--header-height);
  width: 100vw;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  box-shadow: 0 0 4px hsla(0, 0%, 75.7%, 0.35);

  .logo {
    margin-bottom: -4px; // to offset something the a tag wrapper is doing
    height: 44px;
    width: 44px;
    font-weight: 900;
    fill: var(--NickelPrimary);
    font-size: 40px;
  }

  .right-items {
    &.signed-in {
      height: 100%;
    }

    .last-button {
      margin-left: 24px;
    }

    .profile-dropdown {
      position: relative;
      height: 100%;

      .dropdown-button {
        height: 100%;
        width: 330px;
        margin-left: 10px;
        padding-left: 24px;
        padding-right: 48px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-left: 1px solid #eaeaea;
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.8;
        }

        .profile-info {
          .profile-name {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1.25px;
          }

          .account-type {
            opacity: 0.25;
            font-size: 18px;
            letter-spacing: 1.25px;
          }
        }

        .chevron-down {
          height: 40px;
          width: 40px;
          margin-right: -48px;
        }
      }

      .dropdown-menu {
        z-index: 1050;
        position: absolute;
        left: 50%;
        top: calc(100% + 6px);
        width: 300px;
        margin-top: 4px;
        transform: translateX(-50%);
        display: none;
        color: #525257;
        background-color: #fff;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
        border: 1px solid #eaeaea;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        &.open {
          display: block;
        }

        .menu-item {
          width: 100%;
          padding: 16px;
          display: block;
          color: var(--NickelPrimary);
          font-weight: 700;
          cursor: pointer;
          user-select: none;

          &:hover {
            background-color: #fafafa;
          }

          &:active {
            opacity: 0.7;
          }

          &:not(:last-child) {
            border-bottom: 1px solid #eaeaea;
          }
        }
      }
    }
  }
}
