.root {
  height: 100vh;
  width: 100vw;
  background-color: var(--White);

  &.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.main-content {
  height: var(--content-height);
  width: 100vw;
  display: flex;
}

.Toastify {
  > * > * {
    position: relative;
    padding: 8px 8px 8px 24px;
    color: #777;
    border-radius: 8px;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 8px;
      height: 100%;
      background-color: var(--NickelPrimary);
      animation: breathe ease-out 1 forwards;
      animation-duration: 5000ms;
    }
  }

  .Toastify__progress-bar--default {
    background: none;
  }
}

@keyframes breathe {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.1;
  }
}
